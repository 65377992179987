#external-events {
    margin-top: 100px;
    margin-right: 10px;
    width: 250px;
    height: auto;
    z-index: 2;
    padding: 0 10px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    /*overflow-x: scroll;*/
    background-color: #FFFFFF;
    border-radius: 16px;
    display: none;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#mycalendartest {
    margin-left: 50px;
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}
.fc-direction-ltr{
    width: 1000px;
    height: 630px;
}
.fc th {
    width: 140px;
    height: 70px;
}
.fc td {
    width: 140px;
    height: 70px;
}

.fc-col-header{
    width: 100%;
}

.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.demo-app-calendar.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5em;
}

.fc-view-container {
    position: relative;
}

.fc-view{
    position: relative;
    z-index: 1;
}
.fc-view, .fc-view > table {
    position: relative;
    z-index: 1;
}

.fc-scrollgrid{
    background-color: white;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}

.fc td {
    border-style: solid;
    border-width: 1px;
    padding: 0;
    vertical-align: top;
}

.btnStyle{
    background-color:#1E1E2D;
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 4px 15px;
    cursor: pointer;
    border:none;
    margin-left: -460px;
}

.inputStyling{
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    margin-left: -500px;

}

.inputStyling:focus{
    outline: none;
}

@media only screen and (min-width:1450px) and (max-width: 2001px) {
    .inputStyling{
        margin-left: -350px;
    }
    .btnStyle{
        margin-left: -350px;
    }
}

@media only screen and (min-width:1350px) and (max-width: 1700px) {
    .fc th {
        width: 95px;
        height: 40px;
    }

    .fc td {
        width: 95px;
        height: 40px;
    }

    .fc-direction-ltr {
        width: 700px;
        height: 400px;
    }
    .inputStyling{
        margin-left: -350px;
    }
    .btnStyle{
        margin-left: -350px;
    }
}

@media only screen and (min-width:1000px) and (max-width: 1349px) {
    .fc th {
        width: 50px;
        height: 25px;
    }
    .fc td {
        width: 50px;
        height: 25px;
    }
    .fc-direction-ltr{
        width: 380px;
        height: 450px;
    }
    .row{
        margin-right: 0;
    }
    #mycalendartest{
        padding: 30px 10px;
    }
    .inputStyling{
        margin-left: -350px;
    }
    .btnStyle{
        margin-left: -350px;
    }
}

@media only screen and (min-width:768px) and (max-width: 1000px) {
    .fc th {
        width: 40px;
        height: 25px;
    }

    .fc td {
        width: 40px;
        height: 25px;
    }

    .fc-direction-ltr {
        width: 250px;
        height: 450px;
    }

    .row {
        margin-right: 0;
        margin-left: -40px;
    }

    #mycalendartest {
        padding: 30px 10px;
    }

    .fc-scrollgrid {
        overflow-x: scroll !important
    }

    .fc .fc-scroller {
        overflow-x: scroll !important
    }

    .fc-daygrid-body {
        overflow-x: scroll;
    }
    .inputStyling{
        margin-left: -320px;
    }
    .btnStyle{
        margin-left: -320px;
    }
    .fc-toolbar-chunk{
        display: flex;
    }
}
