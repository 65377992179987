.textVerify{
    font-family: Inter_Medium,sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #303030;
    max-width: 596px;
}

.error{
    font-family:Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: red;
    text-align: center;
    margin-top: 5px;
}

@media only screen and (min-width:1024px) and (max-width: 1440px){
    .textVerify{
        max-width: 500px;
    }
}

@media only screen and (min-width:850px) and (max-width: 1024px){
    .textVerify{
        max-width: 400px;
    }
}

@media only screen and (min-width:768px) and (max-width: 850px){
    .textVerify{
        max-width: 330px;
    }
}