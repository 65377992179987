.textCongratulation{
    max-width: 354px;
    width: 100%;
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #1E1E2D;
    margin-top: 68px;
}


@media only screen and (min-width:768px) and (max-width: 1024px) {
    .textCongratulation{
        font-size: 16px;
        margin-top:22px;
    }
}