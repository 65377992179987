.profileDiv{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
    height: 100%;
}

.profileText{
    margin-top: 32px;
    margin-bottom: 32px;
    max-width: 748px;
    width: 100%;
    text-align: center;
    font-family: Inter_Medium,sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #1E1E2D;
}