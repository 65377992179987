.footerWhole{
    background: #FFFFFF;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
    padding: 20px 30px;
}

.footerText{
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #A3A7B9;
}