.signUpError{
    font-family:Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: red;
    text-align: center;
    margin-top: 5px;
}

.belowDiv{
    margin-top: 24px;
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #303030;
}

.sign{
    font-family: Inter_Medium,sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2697D4;
    margin-left: 5px;
    cursor: pointer;
}