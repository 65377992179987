.react-calendar{
    height: 320px;
    margin-top: 130px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    margin-left: 20px;
}

.react-calendar__navigation__label{
    font-family: Inter_Bold,sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color:black;
}

.mainStyle{
    display: flex;
    justify-content: space-between;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
    font-size: 22px !important
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    font-size: 22px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button{
    font-size: 22px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
    font-size: 22px;
}


.react-calendar__month-view__weekdays{
    font-family: Inter_Bold,sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color:black;
}

/*@media only screen  and (max-width: 2000px) {*/
/*    .mainStyle{*/
/*        justify-content: unset;*/
/*    }*/
/*}*/

@media only screen and (min-width:768px) and (max-width: 1000px) {
    .react-calendar{
        margin-left: 0;
        padding-left:8px;
        padding-right:8px;
        width: unset;
    }
}