.settingsWhole{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    height: 100%;
    padding: 24px 64px;
}
.settingsUpPart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #F0F1F2;
    margin-bottom: 64px;
}

.settingsTitle{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #1E1E2D;
}

.settingsButton {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 179px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2697D4;
    border-radius: 4px;
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.settingsPart{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.settingsName{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #A3A7B9;
    width: 144px;
    margin-right: 140px;
}

.settingsData{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #1E1E2D;
}

.greenDiv{
    margin-left: 16px;
    width: 93px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
    background: #54CC8D;
    border-radius: 8px;
}

.linkBtn{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2697D4;
    cursor: pointer;
}

.labelAndInput{
    margin-bottom: 24px;
}

.labelDiv{
    display: block;
    margin-bottom: 5px;
    font-family: Inter-Regular, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E2D;
}

.inputDiv{
    width:570px;
    padding: 12px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
    background: #FFFFFF;
    border: 1px solid #A3A7B9;
    border-radius: 4px;
}

.inputDiv:focus {
    outline: none;
}

.textDanger{
    font-family:Inter_Regular, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #CB2D25;
    margin-top: 4px;
    max-width: 520px;
    width:570px;
}
.settingsEditPart{
    display: flex;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #F0F1F2;
    width: 569px;
    justify-content: space-between;
    align-items: center;
}

.settingsEditName{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #A3A7B9;
}

.redDiv{
    margin-left: 16px;
    width: 93px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
    background: red;
    border-radius: 8px;
}

.buttonsDiv{
    display: flex;
    gap: 10px;
}

@media only screen and (min-width:768px) and (max-width: 1000px){
    .settingsWhole{
        padding: 24px 15px;
    }
    .settingsButton {
        width: 140px;
    }
}