.label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-weight: 500;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto;
    gap: 10px;
    margin: 8px 0;
    padding: 6px;
}


.block {
    position: relative;
    width: 22px;
    height: 22px;
}

.checkmark_container {
    border: 2px solid blue;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmark_container>span {
    background-color: blue;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    transform: scale(0);
    transition: all 180ms ease;
}

.label>input:checked+.block .checkmark_container>span {
    transform: scale(1);
}

.container {
    --form-control-color: #d2d2d2;
    --form-control-disabled: #d2d2d2;
    color: #646464;
    user-select: none;
    font-size: 16px !important;
    display: flex;
}

.form-control {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1E1E2D;



    display: grid;
    grid-template-columns: 1em auto;
    grid-gap: 0 20px;
    align-items: center;
    cursor: pointer;
}

.form-control+.form-control {
    margin-top: 1em;
}

.form-control--disabled {
    color: white;
    cursor: not-allowed;
}

.form-control input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    /*color: #646464;*/
    width: 1.3em;
    height: 1.3em;
    border: 1px solid #2697D4;
    border-radius: 4px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.form-control input[type="checkbox"]::before {
    content: "";
    width: 0.72em;
    height: 0.72em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    /*background-color: CanvasText;*/
}

input[type="checkbox"]:hover {
    background-color: #2697D4;
}

input[type="checkbox"]:checked {
    background: #2697D4;
    border: 1px solid #2697D4;
    border-radius: 4px;
    color: white;
}

input[type="checkbox"]:not(:checked) {
    border: 1px solid #2697D4;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    --form-control-color: #d2d2d2;
    color: #d2d2d2;
    cursor: not-allowed;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border: 1px solid #2697D4;
    border-radius: 4px;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: gray;
}
