.whole{
    display: flex;
}
.appointmentsWhole{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 24px 64px;
    display: flex;
    height: 100vh;
}

.leftPart{
    display: flex;
    flex-direction: column;
    margin-right: 150px;
}

.nameSurname{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E2D;
    margin-bottom: 20px;
}

.title{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #1E1E2D;
    margin-bottom: 20px;
}

.duration{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E2D;
    display: flex;
    align-items: center;
}

.timeZoneLabel{
    margin-top: 20px;
    display: block;
    font-family:Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E2D;
    margin-bottom: 5px;
    margin-left: 20px;
}

.timeZoneInput{
    width: 350px;
    padding: 12px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E2D;
    background: #FFFFFF;
    border:1px solid #A3A7B9;
    border-radius: 4px;
    margin-left: 20px;
}
.timeZoneInput:focus{
    outline: none;
}
.middlePart{
    display: flex;
}

.timeAndDate{
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2697D4;
    margin-bottom: 20px;
}

.time{
    width: 200px;
    height: 50px;
    padding-top:10px;
    padding-bottom: 10px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2697D4;
    cursor: pointer;
    text-align: center;
    border: 2px solid #2697D4;
    margin-top: 20px;
    margin-right: 10px;
}

.time:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.timeDiv{
    overflow-y: auto;

    /*height: 600px;*/
    width: 220px;
}
.timing{
    margin-left: 100px;
}

.calendarAppointments{
    margin-top: 10px !important;
}

.iconStyle{
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

.buttons{
    display: flex;
    justify-content: space-between;
    max-width: 200px;
}

.cancelBtn{
    width: 90px;
    padding: 8px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
    color: #2697D4;
    background: #FFFFFF;
    border:1px solid  #2697D4;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
    border-radius: 4px;
}
.cancelBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

.checkBtn{
    margin-top: 20px;
    padding: 8px;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
    color: #2697D4;
    background: #FFFFFF;
    border:1px solid  #2697D4;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 20px;
}
.checkBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

.titleNew{
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    color: #1E1E2D;
    margin-bottom: 3px;
    text-align: center;
    padding-top: 200px;
}