.buttonsDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin: 0 auto 8px;
}

.buttonFBLinkedIn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #1877F2;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 306px;
    font-family: Inter-Regular, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
}

.iconStyle{
    margin-right:8px;
    object-fit:contain;
}

@media only screen and (min-width:768px) and (max-width: 1030px){
    .buttonsDiv{
        flex-direction: column;
    }
    .buttonFBLinkedIn{
        width: 260px
    }
}