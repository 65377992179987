.modalText{
    font-family: Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #303030;
    margin-bottom: 24px;
}

.resetLink{
    font-family:Inter_Medium, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    color: #2697D4;
    line-height: 150%;
    margin-top: 16px;
    text-align: left;
    cursor: pointer;
}

.resetPasswordError{
    font-family: Inter-Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: red;
    text-align: center;
    margin-top: 5px;
}