.btnStyle{
    background-color:#2697D4;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    text-align: center;
    margin-top:48px;
}
.btnStyle:disabled{
    cursor: not-allowed;
    color: #A3A7B9;
    background-color: #F0F1F2;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

@media only screen and (min-width:850px) and (max-width: 1024px) {
    .btnStyle{
        line-height: 20px;
        width: 400px !important;
    }
}

@media only screen and (min-width:768px) and (max-width: 850px) {
    .btnStyle{
        line-height: 20px;
        width:300px !important;
    }
}
