.addText{
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E2D;
    margin-bottom: 24px;
}

.inputAndBtn{
    display: flex;
    align-items: center;
}

.addBtn{
    width: 179px;
    margin-top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2697D4;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Inter_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-left: 16px;
    cursor: pointer;
    border: none;
}

.whiteIconBtn{
    margin-right: 8px;
}

.tagsWhole{
    display: flex;
    gap:12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #F0F1F2;
    margin-bottom: 16px;
    max-width: 520px;
    width: 100%;
    overflow-x: auto;
}

.saveBtn{
    width: 179px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2697D4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-family: Inter-Regular, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.saveBtn:disabled{
    cursor:not-allowed;
}